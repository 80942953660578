import './App.css';
import Taskbar from './components/Taskbar';
import Footer from './components/Footer';
import Homepage from './components/Homepage';
import Aboutus from './components/Aboutus';
import Services from './components/Services';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from './components/Home';
function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
      <Taskbar />
       <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/aboutus" element={<Aboutus />} />
      <Route path="/services" element={<Services />} />

     </Routes> 
      <Footer />
      </BrowserRouter>
      
    </div>
  );
}

export default App;
