import React from "react";
import { FaUserAstronaut,FaPhoneAlt } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { FaFacebookF,FaLinkedinIn, FaInstagram, FaWhatsapp} from "react-icons/fa";
import whatsapp from "../assets/whatsapp.png";
import linkedin from "../assets/linkedin.png";
import fb from "../assets/fb.png";
import insta from "../assets/insta.png";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const Footer = () => {

  const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};

  return (
    <div className="">
    <div className="bg-black lg:h-96 ">
      <ul className="flex flex-col lg:flex-row justify-between items-center">
      <li className="text-white mb-6 lg:mb-0">
          <div>
            <iframe className="w-screen h-96 lg:w-96" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=Zone-51,%20Road-620,%20building-17,%20Doha+(Al%20Ahsan%20Group)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps vehicle tracker</a></iframe>
          </div>
        </li>
        <li className="text-white mb-6 lg:mb-0">
          <div>
            <h1 className="text-5xl mt-4 md:py-8 py-2">Get in touch</h1>
            <div className="flex items-center my-1">
              <IoCallOutline className="h-6 w-6 mr-2"/>
              <a href="tel:+97471941978">+974 7194 1978</a>
            </div>
            <div className="flex items-center my-1">
              <MdOutlineEmail className="h-6 w-6 mr-2"/>
              <a href="mailto:info@alahsangroup.com">info@alahsangroup.com</a>
            </div>
            <div className="flex items-center my-1">
              <IoLocationOutline className="h-6 w-6 mr-2"/>
              <p>Zone-51, Road-620, building-17, Doha</p>
            </div>
          </div>
        </li>
        <li className="text-white mb-16 lg:mb-0 md:mr-16">
          <div>
            <h1 className="text-5xl mb-2 md:py-6 py-2">Reach out</h1>
            <div className="flex justify-center">
              <div className="flex items-center mx-2">
              <a href="https://wa.me/+97471941978?text=Hi" target="_blank" rel="noopener noreferrer">
                  <button className="flex items-center">
                    <FaWhatsapp className="h-5 w-5 mr-1"/>
                    <p>WhatsApp</p>
                  </button>
                </a>
              </div>
              <div className="flex items-center mx-2">
              <a href="https://www.linkedin.com/in/al-ahsan-facilities-b83876305/" target="_blank" rel="noopener noreferrer">
                  <button className="flex items-center">
                    <FaLinkedinIn className="h-5 w-5 mr-1"/>
                    <p>Linked In</p>
                  </button>
                </a>
              </div>
              <div className="flex items-center mx-2">
              <a href="https://www.facebook.com/profile.php?id=61558650190323&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                  <button className="flex items-center">
                    <FaFacebookF className="h-5 w-5 mr-1"/>
                    <p>Facebook</p>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </li>
    
      </ul>
    </div>
  </div>
  );
};

export default Footer;
