import React, { useState } from "react";
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const Taskbar = (props) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = () => {
    navigate("/");
  };

  const handleAboutUs = () => {
    navigate("/aboutus");
  };

  const handleServices = () => {
    navigate("/services");
  };

  const handleContactUs = () => {
    scroll.scrollToBottom();
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div>
      <div className="w-full h-20 bg-white">
        <ul className="flex justify-between items-center text-xl font-bold">
          <li className="flex flex-row">
            <button onClick={handleClick}>
              <img src={logo} alt="Logo" className="h-16 mt-2 ml-6 cursor-pointer" />
            </button>
            <div className="md:hidden justify-center flex flex-col mr-7">
            <p className="md:hidden text-black/80 items-center ml-3 text-xl font-body font-light flex tracking-wider pt-1">AL AHSAN</p>
            <p className=" pl-2  text-[#e36837]/80 items-center ml-3 text-xl flex">أل أحسن</p>
            </div>
          </li>
          <div className="hidden md:flex row justify-center space-x-4">
            <button type="button" onClick={handleClick} className="nav-button md:px-6 md:py-1 rounded-3xl hover:bg-orange-500 hover:text-white active:bg-orange-700 focus:outline-none focus:text-white focus:bg-orange-500">
              Home
            </button>
            <li>
              <button onClick={handleAboutUs} className="nav-button md:px-6 md:py-1 rounded-3xl hover:bg-orange-500 hover:text-white active:bg-orange-700 focus:outline-none focus:text-white focus:bg-orange-500">
                About us
              </button>
            </li>
            <button onClick={handleServices} className="nav-button md:px-6 md:py-1 rounded-3xl hover:bg-orange-500 hover:text-white active:bg-orange-700 focus:outline-none focus:text-white focus:bg-orange-500">
              Services
            </button>
            <button onClick={handleContactUs} className="nav-button md:px-6 md:py-1  rounded-3xl hover:bg-orange-500 hover:text-white active:bg-orange-700 focus:outline-none focus:text-white focus:bg-orange-500">
              Contact us
            </button>
          </div>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="h-10 w-10 flex items-center justify-center">
              {showMenu ? (
                <IoCloseSharp className="h-6 w-6" />
              ) : (
                <HiMenuAlt3 className="h-6 w-6" />
              )}
            </button>
          </div>
          <div className={`md:hidden drop shadow-2xl absolute top-20 right-2 bg-white rounded-md z-50 my-2 ${showMenu ? "block" : "hidden"}`}>
            <ul className="flex flex-col space-y-2 items-end mx-4 my-2">
              <button type="button" onClick={handleClick} className="font-light nav-button focus:text-orange-500 active:text-orange-500 focus:font-bold">
                Home
              </button>
              <button onClick={handleAboutUs} className="font-light nav-button focus:text-orange-500 active:text-orange-500 focus:font-bold">
                About us
              </button>
              <button onClick={handleServices} className="font-light nav-button focus:text-orange-500 active:text-orange-500 focus:font-bold">
                Services
              </button>
              <button onClick={handleContactUs} className="font-light nav-button focus:text-orange-500 active:text-orange-500 focus:font-bold">
                Contact us
              </button>
              <button onClick={toggleMenu} className="font-light nav-button focus:text-orange-500 active:text-orange-500 focus:font-bold">
                Close
              </button>
            </ul>
          </div>
          <div className="hidden md:flex row width:33%">
            <li className="cursor-pointer float-right mx-4">
              <a href="https://www.linkedin.com/in/al-ahsan-facilities-b83876305/" target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn className="h-5 w-5" />
              </a>
            </li>
            <li className="cursor-pointer float-right mx-4">
            <a href="https://www.facebook.com/profile.php?id=61558650190323&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <FaFacebookF className="h-5 w-5" />
            </a>
            </li>
            <a href="https://wa.me/+97471941978?text=Hi" target="_blank" rel="noopener noreferrer">
              <li className="cursor-pointer float-right mx-4">
                <FaWhatsapp className="h-5 w-5" />
              </li>
            </a>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Taskbar;
