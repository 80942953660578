import React from 'react';
import { BsBuildingFillGear } from 'react-icons/bs';
import { MdCleaningServices, MdOutlineLandscape, MdOutlineSecurity, MdSafetyDivider } from 'react-icons/md';
import { IoBuild } from 'react-icons/io5';

const Services = () => {
  return (
    <div className="bg-white min-h-screen mb-10">
      <div className="text-4xl md:text-5xl text-center md:py-10 md:mb-10 ">
        Our services
      </div>
      <div className=" mt-20 grid grid-cols-1 md:grid-cols-3 gap-7 justify-center md:px-24 px-4">
        {/* Service Card 1 */}
        <div className="bg-white rounded-xl shadow-lg hover:shadow-orange-500">
          <div className="flex justify-center items-center text-5xl py-6">
            <BsBuildingFillGear />
          </div>
          <h1 className="text-2xl mb-2 text-bold text-center">Facility Coordination</h1>
          <p className="text-xl mb-6 px-2">Efficiently oversee all aspects of facility management with our integrated service, ensuring seamless operations and client satisfaction.</p>
        </div>
        
        {/* Service Card 2 */}
        <div className="bg-white rounded-lg shadow-lg hover:shadow-orange-500">
          <div className="flex justify-center items-center py-6">
            <IoBuild className="text-5xl" />
          </div>
          <h1 className="text-2xl mb-2 text-bold text-center">Structural Enhancement Solutions</h1>
          <p className="text-xl mb-6 px-2">Utilize our hard service for robust construction and maintenance solutions, ensuring structural integrity and longevity.</p>
        </div>
        
        {/* Service Card 3 */}
        <div className="bg-white rounded-lg shadow-lg hover:shadow-orange-500"> 
          <div className="flex justify-center items-center py-6">
            <MdCleaningServices className="text-5xl" />
          </div>
          <h1 className="text-2xl mb-2 text-bold text-center">Premium Facility Care</h1>
          <p className="text-xl mb-6 px-2">Benefit from cleaning, hospitality, and support solutions with our soft services and enhance your ambiance and comfort.</p>
        </div>
        
        {/* Service Card 4 */}
        <div className="bg-white rounded-lg shadow-lg hover:shadow-orange-500">
          <div className="flex justify-center items-center text-5xl py-6">
            <MdOutlineSecurity/>
          </div>
          <h1 className="text-2xl mb-2 text-bold text-center">Safety Compliance Solutions</h1>
          <p className="text-xl mb-6 px-2">Gain access to safety protocols and premiere equipment and ensure adherence to regulatory practices and personal protection.</p>
        </div>
        
        {/* Service Card 5 */}
        <div className="bg-white rounded-lg shadow-lg hover:shadow-orange-500">
          <div className="flex justify-center items-center text-5xl py-6">
            <MdSafetyDivider />
          </div>
          <h1 className="text-2xl mb-2 text-bold text-center">Protective Integrity Solutions</h1>
          <p className="text-xl mb-6 px-2">Rely on our security services for comprehensive asset protection, featuring advanced surveillance and trained personnel.</p>
        </div>
        
        {/* Service Card 6 */}
        <div className="bg-white rounded-lg shadow-lg hover:shadow-orange-500">
          <div className="flex justify-center items-center py-6">
            <MdOutlineLandscape className="text-5xl" />
          </div>
          <h1 className="text-2xl mb-2 text-bold text-center">Landscaping</h1>
          <p className="text-xl mb-6 px-2">Our expertise covers building captivating outdoor environments with our pools and landscaping service, with skilled designs and meticulous maintenance.</p>
        </div>
      </div>
    </div>
  );
};

export default Services;
