import React from "react";
import site3 from "../assets/site3.jpg";  
import site4 from "../assets/site4.jpg";

const Homepage = () => {
  return (
    <div>

   
    <div className="relative ">
      <img src={site3} alt="" className="w-full hidden md:block" />
      <div className="absolute top-1/4 left-0 w-full text-center md:text-left">
        <div className="hidden md:block font-sans text-white text-5xl font-bold md:w-1/2 md:pl-10">
          We build with precision,<br /> and design every <span className="text-orange-500">detail.</span>
        </div>
      </div>
      
    </div>
      <img src={site4} alt="" className="w-full md:hidden" />
      <div className="absolute top-1/4 left-0 w-full text-center md:text-left bg-black/20 ">
      <div className=" ">

      <p className="md:hidden text-left ml-4 font-sans text-white text-7xl font-bold md:w-1/2 md:pl-10 my-10">
          We build <br></br> with<br></br> precision,<br></br> and design every <br></br> <span className="text-orange-500">detail.</span>
        </p>
      </div>
      </div>
      </div>
  );
};

export default Homepage;