import React from "react";
import aboutus from "../assets/aboutus.jpg";
import about from "../assets/about.jpg";
import us from "../assets/us.jpg";

const Aboutus = () => {
  return (
    <div>
      <div className="flex flex-col ">
        <div className="sm:flex sm:items-center">
          <div className="sm:w-1/2 md:px-10">
            <h1 className="md:text-5xl text-4xl mb-6 ">About us</h1>
            <p className="md:text-3xl text-2xl px-6 mb-8">At Alahsan, we pride ourselves on being a premier provider of facility management services in Qatar. With a steadfast commitment to excellence, we cater to a diverse range of needs for both individuals and establishments alike. From construction and contracting to manpower recruitment, fit-out solutions, landscaping, and beyond, we offer brilliant hard services designed to make life easier.</p>
          </div>
          <div className="sm:w-1/2">
            <img src={us} alt="" className="w-full h-auto sm:h-full"></img>
          </div>
        </div>
        <div className=" sm:flex sm:items-center">
          <div className="lg:block hidden sm:w-1/2">
            <img src={about} alt="" className="w-full h-auto"></img>
          </div>
          <div className="sm:w-1/2 md:px-10">
            <h1 className="text-4xl mt-10 mb-6">Our Mission</h1>
            <p className="text-2xl px-6 ">We at Alahsan strive to extend professional services to those in need while prioritizing quality and affordability. We aim to make a meaningful difference in the lives of our clients while setting a standard of excellence in the industry. We are committed to transparency, accountability, and fostering long-term relationships in all our interactions, ensuring that our clients feel supported and valued every step of the way.</p>
            <h1 className="text-4xl mt-14 mb-6">Our Vision</h1>
            <p className="text-2xl px-6 mb-14">Our vision is to emerge as a distinguished global entity in the realm of Facility Management and establish ourselves in daily operations of individuals and enterprises worldwide. We aspire to be recognized as a pioneering leader in our field, setting new benchmarks for excellence and integrity.</p>
          </div>
          <div className=" md:hidden block sm:w-1/2">
            <img src={about} alt="" className="w-full h-auto"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
